import { useState, useEffect } from "react"
import { animate } from "framer-motion"
import { Frame } from "framer"

export function DailyCounter() {
    const targetNumber = 10000000 // The target number at midnight
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        const now = new Date()
        const midnight = new Date(now)
        midnight.setHours(24, 0, 0, 0) // Set to midnight

        // Get the total seconds in the day
        const totalSecondsInDay = 24 * 60 * 60
        // Get the number of seconds that have passed today
        const secondsPassedToday =
            now.getHours() * 60 * 60 + now.getMinutes() * 60 + now.getSeconds()
        // Get the number of seconds left until midnight
        const secondsUntilMidnight = totalSecondsInDay - secondsPassedToday

        // Calculate the current number
        const currentNumber =
            (targetNumber * secondsPassedToday) / totalSecondsInDay

        // Set the counter to the current value
        setCounter(currentNumber)

        // Animate the counter smoothly over the remaining time until midnight
        animate(currentNumber, targetNumber, {
            duration: secondsUntilMidnight, // Animate over the remaining seconds
            onUpdate(value) {
                setCounter(value)
            },
            ease: "linear", // Use linear easing to ensure smooth, constant speed
        })
    }, [])

    return (
        <Frame
            style={{
                fontSize: "40px",
                textAlign: "center",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {Math.floor(counter).toLocaleString()}{" "}
            {/* Display the counter with commas */}
        </Frame>
    )
}
